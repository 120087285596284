import Vue from "vue";
import Vuex from "vuex";

import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import auth from "./auth";
import talents from "./talents";
import clients from "./clients";
import contracts from "./contracts";
import codingChallenge from "./codingChallenge";
import talentProfile from "./talent-profile";
import shared from "./shared";
import aiCallResults from "./aiCallResults";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
  },
  getters: {
    getLoadingStatus(state) {
      return state.loading;
    },
  },
  mutations: {
    setLoadingStatus(state, payload) {
      state.loading = payload;
    },
  },
  actions: {},
  modules: {
    auth,
    talents,
    clients,
    contracts,
    talentProfile,
    shared,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    codingChallenge,
    aiCallResults,
  },
});
