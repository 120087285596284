import axios from "axios";

const state = {
  aiCallResults: [],
  aiCallResultsMeta: null,
  aiStatistics: null,
};

const getters = {
  getAICallResults(state) {
    return state.aiCallResults;
  },
  getAICallResultsMeta(state) {
    return state.aiCallResultsMeta || { total: 0, last_page: 1 };
  },
  getAIStatistics(state) {
    return state.aiStatistics;
  },
};

const mutations = {
  setAICallResults(state, payload) {
    state.aiCallResults = payload.data;
    state.aiCallResultsMeta = payload.meta;
  },
  setAIStatistics(state, payload) {
    state.aiStatistics = payload;
  },
};

const actions = {
  fetchAICallResults({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("/prompt-calls", { params: payload })
        .then((res) => {
          commit("setAICallResults", res.data);
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },
  fetchAIStatistics({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/prompt-calls/stats")
        .then((res) => {
          commit("setAIStatistics", res.data.data.meta);
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
